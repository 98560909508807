import clsx from "clsx";

const TabsPills = (props) => {
  const { tabs, onChange, curTab } = props;

  return (
    <div className="w-full">
      <div className="sm:hidden">
        <select
          id="tabs"
          name="tabs"
          defaultValue={curTab}
          onChange={(e) => onChange(e.target.value)}
          className="block w-full rounded-md border-slate-300 focus:border-indigo-500 focus:ring-indigo-500"
        >
          {tabs.map((tab, idx) => (
            <option key={idx}>{tab}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav aria-label="Tabs" className="flex space-x-4">
          {tabs.map((tab, idx) => (
            <button
              type="button"
              key={idx}
              onClick={() => onChange(tab)}
              className={clsx(
                "rounded-md px-3 py-2 text-sm font-medium",
                tab === curTab ? "bg-indigo-100 text-indigo-700" : "text-slate-500 hover:text-slate-700"
              )}
            >
              {tab}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default TabsPills;
