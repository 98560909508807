import { useContext, useEffect, useState } from "react";

import { ApiContext } from "../../providers/ApiProvider";

import LoadingSpinner from "../ui/LoadingSpinner";
import Panel from "../ui/Panel/Panel";
import PanelBody from "../ui/Panel/PanelBody";
import PanelHeader from "../ui/Panel/PanelHeader";

const MyGoals = () => {
  const api = useContext(ApiContext);

  const [goals, setGoals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    api.client
      .get(`/health_goals`)
      .then((resp) => {
        setGoals(resp.data.health_goals || []);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, [api.client]);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {goals.length > 0 && (
            <Panel>
              <PanelHeader>
                <h1 className="text-xl font-bold tracking-tight text-gray-900">Your Health Goals</h1>
              </PanelHeader>
              <PanelBody>
                <dl className="mx-auto grid gap-y-8 grid-cols-4 gap-x-5">
                  {goals.map((goal) => (
                    <div key={goal.id} className="border-t border-indigo-400 pt-4">
                      <dt className="font-medium text-slate-900">{goal.content}</dt>
                      {/* <dd className="mt-2 text-sm text-slate-500">{feature.description}</dd> */}
                    </div>
                  ))}
                </dl>
              </PanelBody>
            </Panel>
          )}
        </>
      )}
    </>
  );
};

export default MyGoals;
