import clsx from "clsx";

const SubmitButton = (props) => {
  const { onSubmit, isValid = true, label = "Save" } = props;

  return (
    <button
      disabled={!isValid}
      type="button"
      onClick={onSubmit}
      className={clsx(
        "w-full rounded-md border border-transparent py-2 text-sm font-medium text-white shadow-sm",
        isValid
          ? "bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
          : "bg-blue-400"
      )}
    >
      {label}
    </button>
  );
};

export default SubmitButton;
