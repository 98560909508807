import { useContext } from "react";

import { Navigate, Outlet, useLocation } from "react-router-dom";

import { Toaster } from "sonner";

import { Disclosure } from "@headlessui/react";

import { AuthContext } from "../../providers/AuthProvider";

import Footer from "../ui/Footer";
import PostHogPageviewTracker from "../../utils/PostHogPageviewTracker";

const navigation = [{ id: 0, name: "Sign In", href: "/login" }];

const AnonLayout = () => {
  const location = useLocation();

  const auth = useContext(AuthContext);

  if (auth.isLoggedIn()) {
    return <Navigate to="/dashboard" replace />;
  }

  if (location.pathname === "/") {
    return <Outlet />;
  } else {
    return (
      <>
        <PostHogPageviewTracker />
        <div className="min-h-full">
          <div className="bg-gray-800 pb-32">
            <Disclosure as="nav" className="bg-gray-800">
              {({ open }) => (
                <>
                  <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="border-b border-gray-700">
                      <div className="flex h-16 items-center justify-between px-4 sm:px-0">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <a href="/">
                              <img className="h-8 w-auto" src="/images/logo.png" alt="Caspia Health" />
                            </a>
                          </div>
                          <h2 className="ml-5 text-xl font-bold tracking-tight text-gray-300">Caspia Health</h2>
                        </div>
                        <div>
                          {navigation.map((item) => (
                            <div key={item.id}>
                              <a
                                href={item.href}
                                className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                              >
                                {item.name}
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Disclosure>
            <header className="py-10"></header>
          </div>

          <main className="-mt-32">
            <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
              <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
                <Outlet />
              </div>
            </div>
          </main>
          <Footer />
          <Toaster richColors position="top-center" />
        </div>
      </>
    );
  }
};

export default AnonLayout;
