import { useRef } from "react";

import Input from "../ui/Input";
import PasswordInput from "../ui/PasswordInput";

const LoginForm = (props) => {
  const { email, onLogin, onForgotPassword, skipForgotPassword = false } = props;

  let emailRef = useRef();
  let passwordRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    onLogin(emailRef.current.value, passwordRef.current.value);
  };

  const handleForgotPassword = () => {
    onForgotPassword(emailRef.current.value);
  };

  return (
    <>
      <div className="flex min-h-full flex-1 items-center justify-center px-4 py-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-sm space-y-10">
          <form className="space-y-3" onSubmit={handleSubmit}>
            <Input ref={emailRef} type="email" name="email" placeholder="Email address" defaultValue={email} />
            <PasswordInput ref={passwordRef} />
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign in
              </button>
            </div>
            {!skipForgotPassword && (
              <div>
                <button
                  type="button"
                  onClick={handleForgotPassword}
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Forgot Password
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
