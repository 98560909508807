import { useContext } from "react";

import { Navigate, Outlet, useLocation } from "react-router-dom";

import { Toaster } from "sonner";
import { Tooltip } from "react-tooltip";

import TopNav from "./TopNav";

import { AuthContext } from "../../providers/AuthProvider";

import Footer from "../ui/Footer";
import PostHogPageviewTracker from "../../utils/PostHogPageviewTracker";

const AuthedLayout = () => {
  const auth = useContext(AuthContext);

  const location = useLocation();

  if (!auth.isLoggedIn()) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return (
    <div className="h-screen flex bg-gray-100">
      <PostHogPageviewTracker />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <TopNav />

        <main className="mt-5 flex flex-1 items-center justify-center relative focus:outline-none">
          <Outlet />
        </main>
        <Footer />
      </div>
      <Toaster richColors position="top-center" />
      <Tooltip id="tooltip" />
    </div>
  );
};

export default AuthedLayout;
