import axios from "axios";

import { createContext } from "react";
import { toast } from "sonner";

export const LOCAL_STORAGE_KEY = "patientTokens";

export const ApiContext = createContext({
  client: {},
});

export function ApiProvider({ baseURL, children }) {
  const client = axios.create({
    baseURL: baseURL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  client.interceptors.request.use((config) => {
    const tokenData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));

    if (tokenData !== null) {
      config.headers["access-token"] = tokenData.accessToken;
      config.headers["client"] = tokenData.clientToken;
      config.headers["uid"] = tokenData.uidToken;
    }

    return config;
  });

  client.interceptors.response.use(
    (response) => {
      if (response.headers.get("access-token")) {
        const tokenData = {
          accessToken: response.headers["access-token"],
          clientToken: response.headers["client"],
          uidToken: response.headers["uid"],
        };

        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(tokenData));
      }

      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        const message = "That didn't work.";
        const subMessage = "Please try again!";
        toast.error(message, { description: subMessage });
      } else if (error.response.status === 500) {
        const message = "We encountered an error. Please reload the page.";
        const subMessage = "Our team has been notified and will investigate!";
        toast.error(message, { description: subMessage });
      } else if (error.response.status === 404) {
        const message = "We couldn't find that.";
        const subMessage = "Please try again";
        toast.error(message, { description: subMessage });
      } else {
        const message = "We encountered an error. Please reload the page.";
        const subMessage = error?.response?.data?.errors;
        toast.error(message, { description: subMessage });
      }

      return Promise.reject(error);
    }
  );

  const context = {
    client,
  };

  return <ApiContext.Provider value={context}>{children}</ApiContext.Provider>;
}
