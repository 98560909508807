import { useState } from "react";

import Panel from "../components/ui/Panel/Panel";
import PanelBody from "../components/ui/Panel/PanelBody";
import PanelHeader from "../components/ui/Panel/PanelHeader";
import TabMenu from "../components/ui/TabMenu";

import Account from "../components/Profile/Account";
import NotificationSettings from "../components/Profile/NotificationSettings";
import Password from "../components/Profile/Password";

const ProfilePage = () => {
  const tabs = {
    account: { name: "Account", value: 0 },
    password: { name: "Password", value: 1 },
    notifications: { name: "Notifications", value: 2 },
    membership: {
      name: "Membership Management",
      value: 3,
      href: process.env.REACT_APP_STRIPE_PORTAL_URL,
      target: "_blank",
    },
  };

  const [curTab, setCurTab] = useState(tabs.account);

  const handleChangeTab = (tab) => {
    setCurTab(tab);
  };

  return (
    <>
      <Panel>
        <PanelHeader>
          <TabMenu tabs={Object.values(tabs)} curTab={curTab} onChange={handleChangeTab} />
        </PanelHeader>
        <PanelBody>
          <>
            {curTab.value === tabs.account.value && <Account />}
            {curTab.value === tabs.password.value && <Password />}
            {curTab.value === tabs.notifications.value && <NotificationSettings />}
          </>
        </PanelBody>
      </Panel>
    </>
  );
};

export default ProfilePage;
