import { useState } from "react";

import {
  RiHeartFill,
  RiHeartLine,
  RiThumbDownFill,
  RiThumbDownLine,
  RiThumbUpFill,
  RiThumbUpLine,
} from "@remixicon/react";

const Tracking = (props) => {
  const { onChange } = props;

  const [hoverDown, setHoverDown] = useState(false);
  const [hoverUp, setHoverUp] = useState(false);
  const [hoverDoubleUp, setHoverDoubleUp] = useState(false);

  return (
    <div className="flex w-full items-center justify-center gap-x-5">
      <div className="text-base text-slate-800">How are you progressing?</div>
      <div className="flex gap-x-5">
        <button
          type="button"
          onMouseEnter={() => setHoverDown(true)}
          onMouseLeave={() => setHoverDown(false)}
          onClick={() => onChange("bad")}
          className="py-1 px-2 text-2xl text-slate-800 hover:shadow-sm hover:ring-1 hover:ring-slate-200 hover:rounded-full"
        >
          😞
        </button>
        <button
          type="button"
          onMouseEnter={() => setHoverUp(true)}
          onMouseLeave={() => setHoverUp(false)}
          onClick={() => onChange("normal")}
          className="py-1 px-2 text-2xl text-slate-800 hover:shadow-sm hover:ring-1 hover:ring-slate-200 hover:rounded-full"
        >
          😐
        </button>
        <button
          type="button"
          onMouseEnter={() => setHoverDoubleUp(true)}
          onMouseLeave={() => setHoverDoubleUp(false)}
          onClick={() => onChange("good")}
          className="py-1 px-2 text-2xl text-slate-800 hover:shadow-sm hover:ring-1 hover:ring-slate-200 hover:rounded-full"
        >
          😀
        </button>
      </div>
    </div>
  );
};

export default Tracking;
