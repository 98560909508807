import ActionItems from "../components/ActionItems/ActionItems";
import MyGoals from "../components/HealthGoals/MyGoals";

const DashboardPage = () => {
  return (
    <div className="flex items-center justify-center px-2 max-w-7xl w-full space-y-5">
      <div>
        <MyGoals />
      </div>
      <div className="flex w-full">
        <ActionItems />
      </div>
    </div>
  );
};

export default DashboardPage;
