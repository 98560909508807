/* eslint-disable jsx-a11y/no-redundant-roles */
import { useContext, useEffect, useState } from "react";

import { usePostHog } from "posthog-js/react";

import { ApiContext } from "../../providers/ApiProvider";

import LoadingSpinner from "../ui/LoadingSpinner";
import Panel from "../ui/Panel/Panel";
import PanelBody from "../ui/Panel/PanelBody";
import PanelHeader from "../ui/Panel/PanelHeader";
import TabsPills from "../ui/TabsPills";

import ActionItem from "./ActionItem";

const tabs = {
  all: "All",
  thinking: "Thinking About It",
  working: "Working On It",
  finished: "Finished It",
};

const ActionItems = () => {
  const api = useContext(ApiContext);

  const posthog = usePostHog();

  const [actions, setActions] = useState([]);
  const [filteredActions, setFilteredActions] = useState([]);
  const [curTab, setCurTab] = useState(tabs.working);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api.client
      .get("/recommendations")
      .then((resp) => {
        setActions(resp.data.patients_recommendations);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [api.client]);

  useEffect(() => {
    const filter = curTab === tabs.all ? actions : actions.filter((r) => tabs[r.state] === curTab);
    setFilteredActions(filter);
  }, [actions, curTab]);

  const handleUpdateItem = (item) => {
    api.client.put(`/recommendations/${item.id}`, { state: item.state, notify: item.notify }).then((resp) => {
      posthog?.capture("patient_action_item_updated", {
        patient: true,
        action_item: item.id,
        state: item.state,
      });
      setActions((prev) => {
        return prev.map((action) => {
          if (action.id === item.id) {
            return resp.data.patients_recommendation;
          }
          return action;
        });
      });
    });
  };

  const handleItemsChange = (item) => {
    setActions((prev) => {
      return prev.map((action) => {
        if (action.id === item.id) {
          return item;
        }
        return action;
      });
    });
  };

  return (
    <div className="relative w-full">
      <PanelHeader>
        <div className="flex items-center justify-between">
          <div>Action Items</div>
          <div className="flex w-full items-center justify-start">
            <TabsPills tabs={Object.values(tabs)} curTab={curTab} onChange={(tab) => setCurTab(tab)} />
          </div>
        </div>
      </PanelHeader>
      <PanelBody>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="relative w-full">
            <ul className="divide-y divide-slate-200 gap-y-2">
              {filteredActions.map((action) => (
                <ActionItem
                  key={action.id}
                  item={action}
                  onChange={handleUpdateItem}
                  onItemChange={handleItemsChange}
                />
              ))}
            </ul>
          </div>
        )}
      </PanelBody>
    </div>
  );
};

export default ActionItems;
