const Footer = () => {
  return (
    <footer className="bg-gray-900 mt-2 sm:mt-12 flex flex-shrink-0">
      <div className="mx-auto max-w-md overflow-hidden py-3 px-2 sm:py-8 sm:px-4 sm:max-w-3xl lg:max-w-7xl">
        <p className="mt-3 text-center text-sm sm:text-base text-gray-400">
          &copy; 2024 Pro Patient Tech, Inc. All rights reserved. &middot;{" "}
          <a href="/privacy" className="hover:underline">
            Privacy Policy
          </a>{" "}
          &middot;{" "}
          <a href="/terms" className="hover:underline">
            Terms of Service
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
