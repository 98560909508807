import { useRef, useState } from "react";

const PhotoSection = (props) => {
  const { alt, onChange } = props;

  const [photo, setPhoto] = useState(props.photo || null);

  const photoRef = useRef();

  const handleRemovePhoto = () => {
    setPhoto(null);
    onChange(undefined);
  };

  const handleChangePhoto = () => {
    photoRef.current.click();
  };

  const onPhotoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      onChange(file);

      let reader = new FileReader();
      reader.onload = (e) => {
        setPhoto(URL.createObjectURL(file));
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="-mt-12 px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
      <div className="group inline-flex overflow-hidden rounded-lg border-4 border-white">
        <input
          type="file"
          onChange={onPhotoChange}
          id="photo-file"
          name="photo-file"
          ref={photoRef}
          className="hidden"
        />
        <div className="relative h-24 w-24 sm:h-40 sm:w-40 lg:w-48 lg:h-48">
          {photo ? (
            <img className="flex-shrink-0" src={photo} alt={alt} />
          ) : (
            <span className="inline-block overflow-hidden bg-gray-100">
              <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-col sm:flex-row sm:ml-6 sm:w-full mt-5 gap-5">
        <button
          onClick={handleChangePhoto}
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Change
        </button>
        {photo && (
          <button
            onClick={handleRemovePhoto}
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-red-400 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            Remove
          </button>
        )}
      </div>
    </div>
  );
};

export default PhotoSection;
