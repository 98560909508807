import { useContext, useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { ApiContext } from "../providers/ApiProvider";

import Panel from "../components/ui/Panel/Panel";
import PanelBody from "../components/ui/Panel/PanelBody";
import TopNav from "../components/layout/TopNav";
import LoadingSpinner from "../components/ui/LoadingSpinner";

const UnsubscribePage = () => {
  const api = useContext(ApiContext);

  // eslint-disable-next-line no-unused-vars
  const [searchParams, _] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const email = searchParams.get("email");

    if (email === undefined || email == null || email.length < 1) {
      setIsLoading(false);
      return;
    }

    api.client
      .delete("/notifications", { data: { email: email } })
      .then((resp) => {
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, [api.client, searchParams]);

  return (
    <div className="min-h-full">
      <TopNav />

      <main className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
        <Panel>
          <PanelBody>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <div className="mt-10 text-center text-lg text-slate-800 font-semibold">
                You have been unsubscribed from question notifications.
              </div>
            )}
          </PanelBody>
        </Panel>
      </main>
    </div>
  );
};

export default UnsubscribePage;
