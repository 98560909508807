import { useRef, createContext, useContext } from "react";

import { ApiContext, LOCAL_STORAGE_KEY } from "./ApiProvider";

const CURRENT_USER_STORAGE_KEY = "patientCurrentUser";

export const AuthContext = createContext({
  currentUser: () => {
    return null;
  },
  login: (email, password) => {},
  logout: () => {},
  isLoggedIn: () => {
    return false;
  },
});

const AuthProvider = ({ children }) => {
  const api = useContext(ApiContext);
  const tokenData = localStorage.getItem(LOCAL_STORAGE_KEY);
  const tokensRef = useRef(
    tokenData === "" ? { accessToken: null, clientToken: null, uidToken: null } : JSON.parse(tokenData)
  );

  const userData = localStorage.getItem(CURRENT_USER_STORAGE_KEY);
  const currentUserRef = useRef(userData === "" ? null : JSON.parse(userData));

  const login = (email, password) => {
    return api.client.post("/auth/sign_in", { email, password }).then((resp) => {
      const data = resp.data.data;

      const user = {
        id: data?.id,
        name: data?.name,
        email: data?.email,
        organizationId: data?.organization_id,
        title: data?.title,
      };

      currentUserRef.current = user;
      localStorage.setItem(CURRENT_USER_STORAGE_KEY, JSON.stringify(user));
    });
  };

  const logout = () => {
    return api.client
      .delete("/auth/sign_out")
      .then(() => {
        resetData();
      })
      .catch((resp) => {
        if (resp?.response?.status === 404) {
          resetData();
        }
      });
  };

  const resetData = () => {
    tokensRef.current = null;
    currentUserRef.current = null;

    localStorage.clear();
  };

  const isLoggedIn = () => {
    if (tokensRef.current === null) {
      const data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
      tokensRef.current = data;
      return data !== null && data.accessToken !== "" && data.accessToken !== null;
    } else {
      return tokensRef.current.accessToken !== "" && tokensRef.current.accessToken !== null;
    }
  };

  const currentUser = () => {
    return currentUserRef.current;
  };

  const context = {
    currentUser,
    login,
    logout,
    isLoggedIn,
  };

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
