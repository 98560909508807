/* eslint-disable jsx-a11y/no-redundant-roles */
import TopNav from "../components/layout/TopNav";
import Footer from "../components/ui/Footer";

const HipaaPage = () => {
  return (
    <div className="bg-white">
      <TopNav />
      <main>
        <div className="mx-10">
          <h1 className="text-3xl font-semibold w-full text-center my-5">HIPAA Notice of Privacy Practices</h1>
          <p className="px-5 py-6 text-sm">
            <strong>CONFIDENTIALITY OF YOUR HEALTH INFORMATION</strong>
          </p>
          <p className="px-5 py-6 text-sm">
            <em>
              THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED AND DISCLOSED AND HOW YOU CAN GET
              ACCESS TO THIS INFORMATION. PLEASE REVIEW IT CAREFULLY.
            </em>
          </p>
          <p className="px-5 py-6 text-sm">
            Our privacy practices reflect applicable federal law as well as state law. The privacy laws of a particular
            state or other federal laws might impose a stricter privacy standard. If these stricter laws apply and are
            not superseded by federal preemption rules under the Employee Retirement Income Security Act of 1974, the
            Plans will comply with the stricter law.
          </p>
          <p className="px-5 py-6 text-sm">
            We are required by law to maintain the privacy and security of your Protected Health Information (PHI).
            Protected Health Information (PHI) is information that is maintained or transmitted by Pro-Patient Tech,
            Inc., which may identify you and that relates to your past, present, or future physical or mental health
            condition and related health care services.
          </p>
          <p className="px-5 py-6 text-sm">
            Some examples of PHI include your name, address, telephone and/or fax number, electronic mail address,
            social security number or other identification number, date of birth, date of treatment, treatment records,
            and enrollment and claims records. We receive, use and disclose your PHI to administer your services as
            permitted or required by law.
          </p>
          <p className="px-5 py-6 text-sm">
            We must follow the federal and state privacy requirements described that apply to our administration of your
            services and provide you with a copy of this notice. We reserve the right to change our privacy practices
            when needed. We will notify you about any changes and promptly post the updated practices within 60 days on
            our website.
          </p>
          <h2 className="text-lg font-medium w-full">PERMITTED USES AND DISCLOSURES OF YOUR PHI</h2>
          <p className="px-5 py-6 text-sm">
            Uses and disclosures of your PHI for treatment, payment, or health care operations.
          </p>
          <p className="px-5 py-6 text-sm">
            Your explicit authorization is not required to disclose information for purposes of health care treatment,
            payments, billing, and other health care operations. Examples of this include processing your payments,
            collecting enrollment information, reviewing the quality of health care you receive, providing customer
            service, resolving your grievances, and determining your eligibility for services.
          </p>
          <p className="px-5 py-6 text-sm">
            As permitted by law, we may disclose PHI to third-party affiliates that perform services on our behalf to
            administer your services. Any third-party affiliates performing services on our behalf has signed a contract
            agreeing to protect the confidentiality of your PHI and has implemented privacy policies and procedures that
            comply with applicable federal and state law.
          </p>
          <p className="px-5 py-6 text-sm">Permitted uses and disclosures without an authorization</p>
          <p className="px-5 py-6 text-sm">
            We are permitted to disclose your PHI upon your request, or to your authorized personal representative (with
            certain exceptions), when required by the U. S. Secretary of Health and Human Services to investigate or
            determine our compliance with the law, and when otherwise required by law. We may disclose your PHI without
            your prior authorization in response to the following:
          </p>
          <ul role="list" className="list-disc px-5 py-3">
            <li>Court order;</li>
            <li>
              Order of a board, commission, or administrative agency for purposes of adjudication pursuant to its lawful
              authority;
            </li>
            <li>Subpoena in a civil action;</li>
            <li>Investigative subpoena of a government board, commission, or agency;</li>
            <li>Subpoena in an arbitration;</li>
            <li>Law enforcement search warrant; or</li>
            <li>Coroner's request during investigations.</li>
          </ul>
          <p className="px-5 py-6 text-sm">
            Some other examples include: to notify or assist in notifying a family member, another person, or a personal
            representative of your condition; to assist in disaster relief efforts; to report victims of abuse, neglect
            or domestic violence to appropriate authorities; for organ donation purposes; to avert a serious threat to
            health or safety; for specialized government functions such as military and veterans activities; and for
            workers' compensation purposes. Information can also be de-identified or summarized so it cannot be traced
            to you and, in selected instances, for research purposes with the proper oversight.
          </p>
          <p className="px-5 py-6 text-sm">Disclosures made with your authorization</p>
          <p className="px-5 py-6 text-sm">
            We will not use or disclose your PHI without your prior written authorization unless permitted by law. If
            you grant an authorization, you can later revoke that authorization, in writing, to stop the future use and
            disclosure.
          </p>
          <h2 className="text-lg font-medium w-full">YOUR RIGHTS REGARDING PHI</h2>
          <p className="px-5 py-6 text-sm">
            You have the right to request an inspection of and obtain a copy of your PHI.
          </p>
          <p className="px-5 py-6 text-sm">
            You may access your PHI by providing a written request. Your request must include (1) your name, address,
            telephone number and identification number, and (2) the PHI you are requesting. We will provide a copy or a
            summary of your health records, usually within 30 days of your request. We may charge a fee for the costs of
            copying, mailing, or other supplies associated with your request. We will only maintain PHI that we obtain
            or utilize in providing your health care services. We may not maintain some PHI after we have completed our
            review of that information. You may need to contact your health care provider to obtain PHI that we do not
            possess.
          </p>
          <p className="px-5 py-6 text-sm">
            You may not inspect or copy PHI compiled in reasonable anticipation of, or use in, a civil, criminal, or
            administrative action or proceeding, or PHI that is otherwise not subject to disclosure under federal or
            state law. In some circumstances, you may have a right to have this decision reviewed.
          </p>
          <p className="px-5 py-6 text-sm">You have the right to request a restriction of your PHI.</p>
          <p className="px-5 py-6 text-sm">
            You have the right to ask that we limit how we use and disclose your PHI; however, you may not restrict our
            legal or permitted uses and disclosures of PHI. While we will consider your request, we are not legally
            required to accept those requests that we cannot reasonably implement or comply with during an emergency.
          </p>
          <p className="px-5 py-6 text-sm">You have the right to correct or update your PHI.</p>
          <p className="px-5 py-6 text-sm">
            You may request to make an amendment of PHI we maintain about you. In certain cases, we may deny your
            request for an amendment. If we deny your request for amendment, you have the right to file a statement of
            disagreement with us and we may prepare a rebuttal to your statement and will provide you with a copy of any
            such rebuttal within 60 days. If your PHI was sent to us by another, we may refer you to that person to
            amend your PHI. For example, we may refer you to your provider to amend your treatment chart.
          </p>
          <p className="px-5 py-6 text-sm">
            You have rights related to the use and disclosure of your PHI for marketing.
          </p>
          <p className="px-5 py-6 text-sm">
            We will obtain your authorization for the use or disclosure of PHI for marketing when required by law. You
            have the right to withdraw your authorization at any time. We do not use your PHI for fundraising purposes.
          </p>
          <p className="px-5 py-6 text-sm">
            You have the right to request or receive confidential communications from us by alternative means or at a
            different address.
          </p>
          <p className="px-5 py-6 text-sm">
            You have the right to receive an accounting of certain disclosures we have made, if any, of your PHI.
          </p>
          <p className="px-5 py-6 text-sm">
            You have a right to an accounting of disclosures with some restrictions. This right does not apply to
            disclosures for purposes of treatment, payment, or health care operations or for information we disclosed
            after we received a valid authorization from you. Additionally, we do not need to account for disclosures
            made to you, to family members or friends involved in your care, or for notification purposes. We do not
            need to account for disclosures made for national security reasons, certain law enforcement purposes or
            disclosures made as part of a limited data set. We’ll provide one accounting a year for free but will charge
            a reasonable, cost-based fee if you ask for another accounting within 12 months.
          </p>
          <p className="px-5 py-6 text-sm">You have the right to a paper copy of this notice.</p>
          <p className="px-5 py-6 text-sm">
            A copy of this notice is posted on our website. You may also request that a copy be sent to you.
          </p>
          <p className="px-5 py-6 text-sm">
            You have the right to be notified following a breach of unsecured protected health information.
          </p>
          <p className="px-5 py-6 text-sm">
            We will notify you in writing, at the address on file, if we discover we compromised the privacy of your
            PHI.
          </p>
          <p className="px-5 py-6 text-sm">You have the right to choose someone to act for you.</p>
          <p className="px-5 py-6 text-sm">
            If you have given someone medical power of attorney or if someone is your legal guardian, that person can
            exercise your rights and make choices about your health information. We will make sure the person has this
            authority and can act for you before we take any action.
          </p>
          <h2 className="text-lg font-medium w-full">COMPLAINTS</h2>
          <p className="px-5 py-6 text-sm">
            You may file a complaint with us and/or with the U. S. Secretary of Health and Human Services if you believe
            we have violated your privacy rights. We will not retaliate against you for filing a complaint.
          </p>
          <h2 className="text-lg font-medium w-full">CONTACTS</h2>
          <p className="px-5 py-6 text-sm">
            You may contact us by emailing help@propatienttech.com, or you may write to the address listed below for
            further information about the complaint process or any of the information contained in this notice.
          </p>
          <p className="px-5 py-6 text-sm">
            <strong>Pro-Patient Tech, Inc.</strong>
          </p>
          <p className="px-5 py-6 text-sm">
            31 Washington Ave, Ste 1<br />
            North Haven, CT 06473
          </p>
          <p className="px-5 py-6 text-sm">
            <em>This notice is effective on and after March 1, 2024.</em>
          </p>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default HipaaPage;
