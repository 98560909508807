import { useContext, useEffect, useState } from "react";

import { toast } from "sonner";

import { ApiContext } from "../../providers/ApiProvider";
import { AuthContext } from "../../providers/AuthProvider";

import Toggle from "../ui/Toggle";
import LoadingSpinner from "../ui/LoadingSpinner";

const NotificationSettings = () => {
  const api = useContext(ApiContext);
  const auth = useContext(AuthContext);

  const [emailNotify, setEmailNotify] = useState(false);
  const [caspiaUpdatesEmail, setCaspiaUpdatesEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [monthlyNewsletter, setMonthlyNewsletter] = useState(false);

  useEffect(() => {
    api.client
      .get(`/patients/${auth.currentUser().id}`)
      .then((resp) => {
        setEmailNotify(resp.data.patient.email_notify);
        setCaspiaUpdatesEmail(resp.data.patient.caspia_updates_email);
        setMonthlyNewsletter(resp.data.patient.monthly_newsletter);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, [api.client, auth]);

  const handleChangeSubscribe = (notification, val) => {
    setIsLoading(true);

    const data = {};

    switch (notification) {
      case "email_notify":
        data.email_notify = val;
        break;
      case "caspia_updates_email":
        data.caspia_updates_email = val;
        break;
      case "monthly_newsletter":
        data.monthly_newsletter = val;
        break;
      default:
        return;
    }

    api.client
      .put(`/patients/${auth.currentUser().id}`, { patient: { ...data } })
      .then((resp) => {
        setEmailNotify(resp.data.patient.email_notify);
        setCaspiaUpdatesEmail(resp.data.patient.caspia_updates_email);
        setMonthlyNewsletter(resp.data.patient.monthly_newsletter);
        setIsLoading(false);
        toast.success("Your notification settings are updated.", "", false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };

  return (
    <div className="mt-10 divide-y divide-gray-200">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <ul className="list-none space-y-5">
          <li>
            <Toggle
              onChange={() => handleChangeSubscribe("email_notify", !emailNotify)}
              enabled={emailNotify}
              label="Receive Question Notifications"
              subLabel="You'll receive questions in your email, sent every other day."
            />
          </li>
          <li>
            <Toggle
              onChange={() => handleChangeSubscribe("monthly_newsletter", !monthlyNewsletter)}
              enabled={monthlyNewsletter}
              label="Receive Healthy Today Newsletter"
              subLabel="Our FREE monthly e-newsletter, with tips, tricks, recipe, and suggestions to help you in your journey towards health."
            />
          </li>
          <li>
            <Toggle
              onChange={() => handleChangeSubscribe("caspia_updates_email", !caspiaUpdatesEmail)}
              enabled={caspiaUpdatesEmail}
              label="Caspia Health Updates"
              subLabel="Infrequent (no more than once every couple of months) updates from Caspia Health when new features and integrations are available (e.g. MyChart, AppleWatch, etc.)"
            />
          </li>
        </ul>
      )}
    </div>
  );
};

export default NotificationSettings;
