import clsx from "clsx";

import { useContext, useState } from "react";

import { useFeatureFlagEnabled } from "posthog-js/react";

import { toast } from "sonner";

import {
  RiNotification2Fill,
  RiNotification2Line,
  RiNotificationOffFill,
  RiNotificationOffLine,
} from "@remixicon/react";

import { ApiContext } from "../../providers/ApiProvider";

import LoadingSpinner from "../ui/LoadingSpinner";
import SelectBox from "../ui/SelectBox";

import Title from "./Title";
import Resources from "./Resources";
import Tracking from "./Tracking";

function buildStateName(icon, name) {
  return (
    <span className="flex gap-x-2">
      {icon} {name}
    </span>
  );
}

const states = [
  {
    id: 0,
    name: buildStateName("🌱", "Thinking About It"),
    value: "thinking",
  },
  {
    id: 1,
    name: buildStateName("💪", "Working On It"),
    value: "working",
  },
  {
    id: 2,
    name: buildStateName("🎉", "Finished It"),
    value: "finished",
  },
];

const ActionItem = (props) => {
  const { item, isLoading, onChange, onItemChange } = props;

  const api = useContext(ApiContext);

  const content = item?.content || item?.recommendation?.dynamic_content || item?.recommendation?.content;

  const [showResources, setShowResources] = useState(false);
  const [notifyHover, setNotifyHover] = useState(false);
  const [progressLoading, setProgressLoading] = useState(false);

  const flagEnabled = useFeatureFlagEnabled("action-item-tracking");

  const handleUpdateItem = (state) => {
    onChange({ ...item, state });
  };

  const handleNotifyChange = () => {
    onChange({ ...item, notify: !item.notify });
  };

  const handleTrackingUpdate = (rating) => {
    setProgressLoading(true);
    api.client
      .post(`/recommendations/${item.id}/progress`, { rating })
      .then((resp) => {
        toast.success("Thanks for sharing!", "We'll check in again soon.", false);
        setProgressLoading(false);
        onItemChange(resp.data.patients_recommendation);
      })
      .catch(() => {
        setProgressLoading(false);
      });
  };

  return (
    <>
      <li
        className={clsx(
          "relative flex flex-col items-center justify-between transition-colors duration-200",
          showResources
            ? "ring-1 ring-indigo-200 rounded-md shadow-md"
            : "hover:bg-slate-50 hover:rounded-md hover:shadow-sm hover:ring-1 hover:ring-slate-200 "
        )}
      >
        {isLoading && (
          <div className="absolute bg-white bg-opacity-60 z-10 h-full w-full flex items-center justify-center">
            <LoadingSpinner small showLoadingText={false} color="text-indigo-600" />
          </div>
        )}
        <div className="flex flex-col w-full p-2 gap-y-2">
          <div className="flex flex-col sm:flex-row w-full justify-between items-center">
            <Title
              pillarNames={item.recommendation?.pillars || []}
              content={content}
              onInfo={() => setShowResources((p) => !p)}
              isExpanded={showResources}
            />

            <div className="mt-2 sm:mt-0 flex items-center justify-between">
              <SelectBox
                optMinW="sm:min-w-48"
                activeValue={states.find((s) => s.value === item?.state) || states[0]}
                values={states}
                onChange={(v) => handleUpdateItem(v.value)}
              />
              {flagEnabled && (
                <span
                  onMouseEnter={() => setNotifyHover(true)}
                  onMouseLeave={() => setNotifyHover(false)}
                  onClick={handleNotifyChange}
                  className="ml-2 cursor-pointer"
                >
                  {item.notify ? (
                    notifyHover ? (
                      <RiNotification2Fill className="text-slate-800 h-5 w-5" />
                    ) : (
                      <RiNotification2Line className="text-slate-800 h-5 w-5" />
                    )
                  ) : notifyHover ? (
                    <RiNotificationOffFill className="text-slate-600 h-5 w-5" />
                  ) : (
                    <RiNotificationOffLine className="text-slate-700 h-5 w-5" />
                  )}
                </span>
              )}
            </div>
          </div>
          {flagEnabled && (
            <div className="relative flex w-full justify-center items-center mt-3 mb-2">
              {progressLoading && (
                <div className="absolute bg-white bg-opacity-60 z-10 h-full w-full flex items-center justify-center">
                  <LoadingSpinner small showLoadingText={false} color="text-indigo-600" />
                </div>
              )}
              {item.check_progress && <Tracking onChange={handleTrackingUpdate} />}
            </div>
          )}
        </div>
        {showResources && <Resources resources={item.recommendation?.resources || []} />}
      </li>
    </>
  );
};

export default ActionItem;
