/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";

import { Fragment, useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { usePostHog } from "posthog-js/react";

import { Disclosure, Menu, Transition } from "@headlessui/react";

import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import { AuthContext } from "../../providers/AuthProvider";
import { ApiContext } from "../../providers/ApiProvider";

const TopNav = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const api = useContext(ApiContext);

  const posthog = usePostHog();

  const [user, setUser] = useState(auth.currentUser());

  useEffect(() => {
    api.client
      .get(`/patients/${user.id}`)
      .then((resp) => {
        setUser(resp.data.patient);
      })
      .catch((resp) => {
        console.error(resp);
      });
  }, [api.client, user.id]);

  useEffect(() => {
    if (user === undefined || user === null) return;

    posthog?.identify(user.id, {
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      patient: true,
    });

    if (!!user.organization) {
      posthog?.group("clinic", user.organization.id);
    }
  }, [posthog, user]);

  const logout = () => {
    auth.logout().then(() => navigate("/"));
  };

  const navigateToProfile = () => {
    navigate("/profile");
  };

  const anonNavigation = [
    { name: "Register", href: "/register" },
    { name: "Log In", href: "/login" },
  ];

  const userNavigation = [
    { name: "Your Profile", onClick: navigateToProfile },
    { name: "Sign out", onClick: logout },
  ];

  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <a href="/dashboard">
                    <img className="h-8 w-auto" src="/images/logo.png" alt="Caspia Health" />
                  </a>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  {auth.isLoggedIn() ? (
                    <div className="flex space-x-4">
                      <a
                        href="/dashboard"
                        className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                      >
                        Dashboard
                      </a>
                      {user?.organization?.appointments_enabled && (
                        <a
                          href="/scheduling"
                          className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                        >
                          Schedule Appointment
                        </a>
                      )}
                    </div>
                  ) : (
                    anonNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))
                  )}
                </div>
              </div>
              {auth.isLoggedIn() && (
                <>
                  <div className="hidden sm:ml-6 sm:block sm:z-50">
                    <div className="flex items-center">
                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="absolute -inset-1.5" />
                            <span className="sr-only">Open user menu</span>
                            {user.photo ? (
                              <img className="h-8 w-8 rounded-full" src={user.photo} alt="" />
                            ) : (
                              <span className="inline-block overflow-hidden h-8 w-8 rounded-full bg-gray-100">
                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                </svg>
                              </span>
                            )}
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <a
                                    onClick={item.onClick}
                                    className={clsx(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                    )}
                                  >
                                    {item.name}
                                  </a>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex sm:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </>
              )}
            </div>
          </div>

          {auth.isLoggedIn() && (
            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                <Disclosure.Button
                  as="a"
                  href="/dashboard"
                  className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                >
                  Dashboard
                </Disclosure.Button>
              </div>
              <div className="border-t border-gray-700 pb-3 pt-4">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    {user.photo ? (
                      <img className="h-8 w-8 rounded-full" src={user.photo} alt="" />
                    ) : (
                      <span className="inline-block overflow-hidden h-8 w-8 rounded-full bg-gray-100">
                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </span>
                    )}
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-white">{user.name}</div>
                  </div>
                </div>
                <div className="mt-3 space-y-1 px-2">
                  {userNavigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      onClick={item.onClick}
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          )}
        </>
      )}
    </Disclosure>
  );
};

export default TopNav;
