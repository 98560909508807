import { useContext, useEffect, useState } from "react";

import { useScript } from "@uidotdev/usehooks";

import { ApiContext } from "../providers/ApiProvider";
import { AuthContext } from "../providers/AuthProvider";

import LoadingSpinner from "../components/ui/LoadingSpinner";

const SchedulePage = () => {
  const auth = useContext(AuthContext);
  const api = useContext(ApiContext);

  const currentUser = auth.currentUser();

  const status = useScript("https://embed.acuityscheduling.com/js/embed.js", {
    removeOnUnmount: false,
  });

  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [calendarOwner, setCalendarOwner] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [patient, setPatient] = useState(null);
  const [scriptLoading, setScriptLoading] = useState(true);

  useEffect(() => {
    setScriptLoading(false);
  }, [status]);

  useEffect(() => {
    api.client
      .get(`/patients/${currentUser.id}`)
      .then((resp) => {
        setPatient(resp.data.patient);
        setCalendarOwner(resp.data.patient.organization.calendar_id);
        setAppointmentTypes(() => {
          return resp.data.patient.organization.appointment_types
            .map((type) => {
              if (type.member_only) {
                return type.id;
              } else {
                return null;
              }
            })
            .filter((t) => t);
        });
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, [api.client, currentUser.id]);

  const scheduleLink = () => {
    let link = `https://app.acuityscheduling.com/schedule.php?ref=embedded_csp`;
    link = `${link}&owner=${calendarOwner}`;
    link = `${link}&firstName=${patient.first_name}`;
    link = `${link}&lastName=${patient.last_name}`;
    link = `${link}&email=${patient.email}`;
    link = `${link}&phone=${patient.phone}`;

    appointmentTypes.forEach((type) => {
      link = `${link}&appointmentType[]=${type}`;
    });

    return link;
  };

  return (
    <>
      {scriptLoading || isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <iframe src={scheduleLink()} title="Schedule Appointment" width="100%" height="100%"></iframe>
        </>
      )}
    </>
  );
};

export default SchedulePage;
