const version = process.env.REACT_APP_VERSION;

const DebugPage = () => {
  return (
    <>
      <ul>
        <li>version: {version}</li>
        <li>
          <button
            type="button"
            onClick={() => {
              throw new Error("Sentry Test Error");
            }}
          >
            Break the world
          </button>
        </li>
      </ul>
    </>
  );
};

export default DebugPage;
