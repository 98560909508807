import { useContext, useState } from "react";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { AuthContext } from "../providers/AuthProvider";

import ErrorFlash from "../components/ui/ErrorFlash";
import LoadingSpinner from "../components/ui/LoadingSpinner";

import LoginForm from "../components/Auth/LoginForm";

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useContext(AuthContext);

  // eslint-disable-next-line no-unused-vars
  const [searchParams, _] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [showLoginError, setShowLoginError] = useState(false);

  const handleLogin = (email, password) => {
    setIsLoading(true);

    auth
      .login(email, password)
      .then(() => {
        const { from } = location.state || {};
        const { pathname, search, state } = from || {};

        navigate((pathname ?? "/dashboard") + (search ?? ""), {
          replace: true,
          state,
        });
      })
      .catch(() => {
        setIsLoading(false);
        setShowLoginError(true);
      });
  };

  const handleForgotPassword = (email) => {
    navigate(`/forgot_password?email=${email}`);
  };

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Sign in to your account</h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="max-w-4xl flex justify-center sm:w-full">
              <div className="w-full flex flex-col justify-center">
                <div className="w-full">
                  {showLoginError && <ErrorFlash errors={["Invalid email or password. Please try again."]} />}
                </div>
                <div className="w-full">
                  <LoginForm
                    email={searchParams.get("email")}
                    onLogin={handleLogin}
                    onForgotPassword={handleForgotPassword}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LoginPage;
