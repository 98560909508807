import { createBrowserRouter, Outlet } from "react-router-dom";

import AuthProvider from "./providers/AuthProvider";

import ErrorPage from "./pages/ErrorPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/LoginPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";

import AnonLayout from "./components/layout/AnonLayout";
import AuthedLayout from "./components/layout/AuthedLayout";
import DashboardPage from "./pages/DashboardPage";
import DebugPage from "./pages/DebugPage";
import HipaaPage from "./pages/HipaaPage";
import PrivacyPage from "./pages/PrivacyPage";
import ProfilePage from "./pages/ProfilePage";
import RegisterPage from "./pages/RegisterPage";
// import ScreeningToolDetailsPage from "./pages/ScreeningToolDetailsPage";
import TermsPage from "./pages/TermsPage";
import UnsubscribePage from "./pages/UnsubscribePage";
import NewsLetterPage from "./pages/NewsLetterPage";
import SchedulePage from "./pages/SchedulePage";

export function buildRoutes() {
  return createBrowserRouter([
    {
      path: "privacy",
      element: <PrivacyPage />,
    },
    {
      path: "newsletter",
      element: <NewsLetterPage />,
    },
    {
      path: "terms",
      element: <TermsPage />,
    },
    {
      path: "hipaa",
      element: <HipaaPage />,
    },
    {
      path: "unsubscribe",
      element: (
        <AuthProvider>
          <UnsubscribePage />
        </AuthProvider>
      ),
    },
    {
      path: "debug",
      element: <DebugPage />,
    },
    {
      path: "/",
      element: (
        <AuthProvider>
          <Outlet />
        </AuthProvider>
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          children: [
            {
              element: <AnonLayout />,
              errorElement: <ErrorPage />,
              children: [
                {
                  index: true,
                  element: <LandingPage />,
                },
                {
                  path: "login",
                  element: <LoginPage />,
                },
                {
                  path: "forgot_password",
                  element: <ForgotPasswordPage />,
                },
                {
                  path: "reset_password",
                  element: <ResetPasswordPage />,
                },
                {
                  path: "register",
                  element: <RegisterPage />,
                },
              ],
            },
            {
              element: <AuthedLayout />,
              errorElement: <ErrorPage />,
              children: [
                {
                  errorElement: <ErrorPage />,
                  children: [
                    {
                      index: true,
                      element: <h1>Authed</h1>,
                    },
                    {
                      path: "dashboard",
                      element: <DashboardPage />,
                    },
                    {
                      path: "scheduling",
                      element: <SchedulePage />,
                    },
                    {
                      path: "profile",
                      element: <ProfilePage />,
                    },
                    // {
                    //   path: "screening-tools",
                    //   children: [
                    //     {
                    //       path: ":id",
                    //       element: <ScreeningToolDetailsPage />,
                    //     },
                    //   ],
                    // },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ]);
}
