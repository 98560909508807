function patientPronouns(pronouns) {
  switch (pronouns) {
    case "she_her":
      return " (she/her)";
    case "he_him":
      return " (he/him)";
    case "they_them":
      return " (they/them)";
    case "he_they":
      return " (he/they)";
    case "she_they":
      return " (she/they)";
    default:
      return "";
  }
}

function buildDob(dob) {
  if (dob === null || dob === undefined) {
    return "";
  }

  const d = new Date(dob);

  return `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${("0" + d.getDate()).slice(-2)}`;
}

const availablePronouns = {
  "": { id: 0, name: "Please Select Patient's Pronouns", value: "" },
  she_her: { id: 1, name: "She/Her", value: "she_her" },
  he_him: { id: 2, name: "He/Him", value: "he_him" },
  they_them: { id: 3, name: "They/Them", value: "they_them" },
  he_they: { id: 4, name: "He/They", value: "he_they" },
  she_they: { id: 5, name: "She/They", value: "she_they" },
  other: { id: 6, name: "Declined", value: "declined" },
};

const availableSexes = {
  "": { id: 0, name: "Please Select Sex", value: "" },
  female: { id: 1, name: "Female", value: "female" },
  male: { id: 2, name: "Male", value: "male" },
};

const availableGenders = {
  "": { id: 0, name: "Please Select Gender", value: "" },
  female_gender: { id: 1, name: "Female", value: "female_gender" },
  male_gender: { id: 2, name: "Male", value: "male_gender" },
  non_binary_gender: { id: 3, name: "Non-Binary", value: "non_binary_gender" },
  trans_gender: { id: 4, name: "Trans", value: "trans_gender" },
  declined_gender: { id: 5, name: "Prefer Not to Say", value: "declined_gender" },
};

const availableEthnicities = {
  "": { id: 0, name: "Please Select Ethnicity", value: "" },
  asian: { id: 1, name: "Asian", value: "asian" },
  black: { id: 2, name: "Black", value: "black" },
  latino: { id: 3, name: "Latino", value: "latino" },
  white: { id: 4, name: "White", value: "white" },
  other: { id: 5, name: "Other", value: "other" },
};

export { patientPronouns, availablePronouns, availableSexes, availableGenders, availableEthnicities, buildDob };
